import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';

// 获取模具台账列表
export function getMouldList(data){
    return request('/mouldAccount/getMouldList.do',"POST",Object.assign({userId:SystemUtils.loginUser.id},data))
}
//获取模具台账详细信息
export function getMouldInfo(data){
    return request('/mouldAccount/getMouldInfo.do',"POST",data)
}
//模具台账上传图片
export function uploadImage(data){
    return request('/mouldAccount/uploadImage.do',"POST",data)
}
//模具台账删除图片
export function deleteImage(data){
    return request('/mouldAccount/deleteImage.do',"POST",data)
}
//保存模具台账
export function updateMouldAccount(data){
    return request('/mouldAccount/updateMouldAccount.do',"POST",data)
}
//发送模具台账
export function sendTask(data){
    return request('/mouldAccount/sendTask.do',"POST",Object.assign({userId:SystemUtils.loginUser.id},data))
}
