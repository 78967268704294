<template >
  <ion-page>
    <Header type="0" title="外协模具信息" v-if="menuRole == 'phone'"> </Header>
    <ion-content scroll-y="true" class="content">
      <div class="root">
        <div class="item">
          <div class="left">项目名称</div>
          <div class="right">
            <ion-text>
              {{ formData.projectName }}
            </ion-text>
          </div>
        </div>
        <div class="item">
          <div class="left">试制任务单号</div>
          <div class="right">
            <ion-text>
              {{ formData.stageCode }}
            </ion-text>
          </div>
        </div>
        <div class="item">
          <div class="left">零部件编号</div>
          <div class="right">
            <ion-text>
              {{ formData.partCode }}
            </ion-text>
          </div>
        </div>
        <div class="item">
          <div class="left">零部件名称</div>
          <div class="right">
            <ion-text>
              {{ formData.partNameCN }}
            </ion-text>
          </div>
        </div>
        <div class="item">
          <div class="left">零部件版本</div>
          <div class="right">
            <ion-text>
              {{ formData.partVersion }}
            </ion-text>
          </div>
        </div>
        <div class="item">
          <div class="left">概算人</div>
          <div class="right">
            <ion-text>
              {{ formData.estimatedPerson }}
            </ion-text>
          </div>
        </div>
        <div class="item">
          <ion-text class="left" slot="start">
            <span class="required">*</span>模具管理开始时间</ion-text
          >
          <ion-datetime
            class="placeholdercolor"
            placeholder="请选择开始时间"
            v-model="formData.mouldManageBeginDate"
            display-format="YYYY-MM-DD"
            cancel-text="取消"
            done-text="确定"
            mode="ios"
            :disabled="$route.query.isEdit == 0 || formData.entryStateId != 1"
          ></ion-datetime>
        </div>
        <div class="item">
          <div class="item remark">
            <ion-text class="left">
              <span class="required">*</span>模具类型</ion-text
            >
            <div style="max-height: 150px; width: 100%; overflow: auto">
              <ion-textarea
                auto-grow="true"
                class="right"
                placeholder="请输入模具类型..."
                maxlength="50"
                v-model="formData.mouldType"
                :disabled="
                  $route.query.isEdit == 0 || formData.entryStateId != 1
                "
              ></ion-textarea>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left"><span class="required">*</span>模具材料</div>
          <div class="right">
            <ion-select
              placeholder="请选择模具材料"
              v-model="formData.mouldMaterial"
              okText="确定"
              cancelText="取消"
              interface="action-sheet"
              :disabled="$route.query.isEdit == 0 || formData.entryStateId != 1"
            >
              <ion-select-option
                v-for="(item, index) in formData.mouldMaterialDic"
                :key="index"
                :value="item.mouldMaterial"
                >{{ item.mouldMaterial }}</ion-select-option
              >
            </ion-select>
            <!-- <ion-text @click="openQrScanner">扫一扫</ion-text> -->
          </div>
        </div>
        <div class="item">
          <div class="left"><span class="required">*</span>模具数量</div>
          <div class="right">
            <ion-input
              placeholder="请输入模具数量"
              maxlength="10"
              v-model="formData.mouldNum"
              :disabled="$route.query.isEdit == 0 || formData.entryStateId != 1"
            ></ion-input>
          </div>
        </div>
        <div class="item remark">
          <ion-text class="left"><span class="required">*</span>模具寿命</ion-text>
          <div class="right">
            <div style="max-height: 150px; width: 100%; overflow: auto">
              <ion-textarea
                auto-grow="true"
                class="right"
                placeholder="请输入模具寿命..."
                maxlength="50"
                v-model="formData.mouldLife"
                :disabled="
                  $route.query.isEdit == 0 || formData.entryStateId != 1
                "
              ></ion-textarea>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left"><span class="required">*</span>是否能用于量产</div>
          <div class="right">
            <ion-select
              placeholder="请选择是否能用于量产"
              v-model="formData.isMouldOutput"
              okText="确定"
              cancelText="取消"
              interface="action-sheet"
              :disabled="$route.query.isEdit == 0 || formData.entryStateId != 1"
            >
              <ion-select-option value="是">是</ion-select-option>
              <ion-select-option value="否">否</ion-select-option>
            </ion-select>
          </div>
        </div>
        <div class="item">
          <div class="left"><span class="required">*</span>是否开模</div>
          <div class="right">
            <ion-select
              placeholder="请选择是否开模"
              v-model="formData.isDieOpening"
              okText="确定"
              cancelText="取消"
              interface="action-sheet"
              :disabled="$route.query.isEdit == 0 || formData.entryStateId != 1"
            >
              <ion-select-option value="是">是</ion-select-option>
              <ion-select-option value="否">否</ion-select-option>
            </ion-select>
          </div>
        </div>
        <div class="item">
          <div class="item remark">
            <ion-text class="left"> 模具图片</ion-text>
            <div style="width: 100%">
              <upload-img
                @modelBack="modelBack"
                :maxLength="
                  $route.query.isEdit == 1 && formData.entryStateId == 1
                    ? 5
                    : formData.mouldImage.length
                "
                :form="formData"
                photo="mouldImage"
                :canRemove="
                  $route.query.isEdit == 1 && formData.entryStateId == 1
                "
                :id="formData.id"
              ></upload-img>
            </div>
          </div>
        </div>
        <div
          class="item"
          v-if="
            formData.ideaMessage &&
            (formData.entryStateId == 1 || formData.entryStateId == 2)
          "
        >
          <div class="item remark">
            <ion-text class="left">意见内容</ion-text>
            <ion-textarea
              auto-grow="true"
              class="right"
              maxlength="200"
              v-model="formData.ideaMessage"
              disabled
            ></ion-textarea>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-button
        expand="block"
        @click="saveWords"
        v-show="$route.query.isEdit == 1 && formData.entryStateId == 1"
        >保存</ion-button
      >
      <ion-button
        expand="block"
        @click="sendWords"
        v-show="
          $route.query.isEdit == 1 &&
          (formData.entryStateId == 2 || formData.entryStateId == 1)
        "
        >发送</ion-button
      >
      <ion-button
        expand="block"
        @click="presentAlertPrompt"
        v-show="$route.query.isEdit == 1 && formData.entryStateId == 2"
        >驳回</ion-button
      >
      <!-- <ion-button v-if="isDrag" expand="block" color="danger">
          <i class="iconfont icon-shanchujieduan"></i>
        </ion-button> -->
    </ion-footer>
  </ion-page>
</template>
<script>
import uploadImg from "@/components/getPhotoTemplate/index.vue";
import Header from "@/components/Header.vue";
import * as dd from "dingtalk-jsapi";
import Utils from "@/utils/Utils.ts";
import {
  getMouldInfo,
  updateMouldAccount,
  sendTask,
} from "@/api/templateInfo/index";
import { loadingController, alertController } from "@ionic/vue";
import SystemUtils from "@/utils/SystemUtils";
export default {
  name: "templateAdd",
  components: {
    uploadImg,
    Header,
  },
  data() {
    return {
      formData: {
        mouldImage: [],
        mouldManageBeginDate: "",
      },
      menuRole: "phone",
    };
  },
  mounted() {
    this.getInfo();
    this.menuRole = localStorage.getItem("menuRole");
    if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "发送", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: "外协模具信息", //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
  },
  methods: {
    getInfo() {
      getMouldInfo({ id: this.$route.query.id }).then((res) => {
        this.formData = res.data.data.resultData[0];
        this.formData.mouldManageBeginDate =
          this.formData.mouldManageBeginDate.substr(
            0,
            this.formData.mouldManageBeginDate.indexOf(" ")
          );
      });
    },
    modelBack(data) {
      this.formData.mouldImage = data;
      console.log(this.formData.mouldImage, 4424232);
    },
    async saveWords() {
      const _this = this;
      if (!_this.formData.mouldManageBeginDate) {
        Utils.presentToastTopWarning("请选择模具管理开始时间", "danger");
        return false;
      } else if (!_this.formData.mouldType) {
        Utils.presentToastTopWarning("请输入模具类型", "danger");
        return false;
      } else if (!_this.formData.mouldMaterial) {
        Utils.presentToastTopWarning("请输入模具材料", "danger");
        return false;
      } else if (!_this.formData.mouldNum) {
        Utils.presentToastTopWarning("请输入数量", "danger");
        return false;
      } else if (!_this.formData.mouldLife) {
        Utils.presentToastTopWarning("请输入模具寿命", "danger");
        return false;
      } else if (!_this.formData.isMouldOutput) {
        Utils.presentToastTopWarning("请选择是否用于量产", "danger");
        return false;
      } else if (!_this.formData.isDieOpening) {
        Utils.presentToastTopWarning("请选择是否开模", "danger");
        return false;
      }
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否保存模具信息",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });
              await loading.present();
              updateMouldAccount(_this.formData).then((res) => {
                loading.dismiss();

                if (!res.data.isError && res.data.data.isSuccess) {
                  _this.$router.go(-1);
                  Utils.presentToastTopWarning("操作成功", "success");
                } else {
                  _this.$router.go(-1);
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "danger"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    async sendWords() {
      const _this = this;
      if (!_this.formData.mouldType) {
        Utils.presentToastTopWarning("请输入模具类型", "danger");
        return false;
      } else if (!_this.formData.mouldMaterial) {
        Utils.presentToastTopWarning("请输入模具材料", "danger");
        return false;
      } else if (!_this.formData.mouldNum) {
        Utils.presentToastTopWarning("请输入数量", "danger");
        return false;
      } else if (!_this.formData.mouldLife) {
        Utils.presentToastTopWarning("请输入模具寿命", "danger");
        return false;
      } else if (!_this.formData.isMouldOutput) {
        Utils.presentToastTopWarning("请选择是否用于量产", "danger");
        return false;
      } else if (!_this.formData.isDieOpening) {
        Utils.presentToastTopWarning("请选择是否开模", "danger");
        return false;
      }
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否发送并保存模具信息",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });
              await loading.present();
              updateMouldAccount(_this.formData).then((res) => {
                var sendType = _this.formData.entryStateId == 1 ? 1 : 0;
                sendTask({
                  id: _this.formData.id,
                  sendType: sendType,
                  userId: SystemUtils.loginUser.id,
                }).then((res) => {
                  loading.dismiss();
                  if (!res.data.isError && res.data.data.isSuccess) {
                    _this.$router.go(-1);
                    Utils.presentToastTopWarning("操作成功", "success");
                  } else {
                    Utils.presentToastTopWarning(
                      res.data.message || res.data.data.message,
                      "danger"
                    );
                  }
                });
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    async presentAlertPrompt() {
      var _this = this;
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "驳回原因",
        inputs: [
          {
            name: "name8",
            type: "textarea",
            placeholder: "请输入驳回原因",
            cssClass: "specialClass",
            attributes: {
              minlength: 1,
              maxLength: 500,
              inputmode: "decimal",
            },
          },
        ],
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async (e) => {
              if (e.name8.trim() == "") {
                Utils.presentToastTopWarning(
                  '请输入驳回原因',
                  "danger"
                );
                return false;
              }

              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });
              await loading.present();

              sendTask({
                id: _this.formData.id,
                sendType: 2,
                ideaMessage: e.name8.trim(),
                userId: SystemUtils.loginUser.id,
              }).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  _this.$router.go(-1);
                  Utils.presentToastTopWarning("操作成功", "success");
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "danger"
                  );
                }
              });
            },
          },
        ],
      });
      return alert.present();
    },
  },
};
</script>
<style lang="scss" scoped>
.checkboxText {
  vertical-align: middle;
}
ion-button {
  --background: #00529c;
}

ion-checkbox {
  vertical-align: middle;
}
.item {
  justify-content: space-between;
}
.root .remark .right {
  width: 100%;
}
</style>